.imageInput {
  padding: 5px 0px;
  img {
    display: block;
    width: 100%;
    max-width: 350px;
    &+.imageButtons {
      margin-top: 20px;
    }
  }
  .imageButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    button+button {
      margin-left: 10px;
    }
  }
  input[type='file'] {
    display: none;
  }
}
