@import '../../variables';

.sendCalendlyButton {
  position: relative;
  width: 100%;
  &.list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    margin: 10px 0px 5px;
  }
  .calendlyIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/images/icons/calendly.png);
    background-size: 27px;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  
}
