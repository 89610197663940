@import '../../_variables.scss';

.editViewWithLogs {
  .tabsView {
    .tabsContainer {
      .tabsList {
        justify-content: flex-start;
      }
    }
  }
  .card {
    border-radius: 0px 0px 4px 4px !important;
  }
  .changeItem {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    border-top: 1px solid $outlineColor;
    .changeTitle {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      label {
        width: 125px;
      }
      span {
        margin-left: 5px;
      }
      b {
        margin-left: 10px;
        font-weight: 600;
        color: #666666;
      }
      .action {
        text-transform: capitalize;
      }
      .author {
        margin-left: 5px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .changeContent {
      display: flex;
      flex-direction: column;
      .fieldItem {
        display: flex;
        flex-direction: row;
        padding: 4px 0px;
        label {
          font-weight: 600;
          color: #666666;
          width: 160px;
        }
        span {
  
        }
      }
    }
  }
  .changeItemGroup {
    & + .changeItemGroup {
      margin-top: 10px;
    }
  }
  .changeGroupTitle {
    font-weight: bold;
    color: #555;
  }
}
