@import '../_variables.scss';

.objectListElem {
  .page-link {
    padding: 0px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      &.clickable {
        cursor: pointer;
        color: $primaryColor;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .actionButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:disabled,
    &:disabled:hover {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: none;
      .material-icons {
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
