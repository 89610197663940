@import '../../variables.scss';

.durationInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  .textInput, .selectInput {
    width: 50%;
  }
}
