.loadIndicator {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 1500;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .circularProgress {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: " ";
        display: block;
        width: 56px;
        height: 55px;
        background-image: url(/images/load_indicator.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        animation: lds-dual-ring 1.2s linear infinite;
      }
    }
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}