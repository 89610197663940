@import '../../variables.scss';

.textInput {
  padding: 8px 0px;
  position: relative;
  &.simplified {
    input,
    input[readonly],
    textarea,
    textarea[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  input,
  textarea {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    &::placeholder {
      color: #999999;
    }
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  textarea {
    resize: none;
    min-height: 100px;
    font-family: inherit;
  }
  .passwordHint {
    position: absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translate(0%, -50%);
      border-style: solid;
      border-width: 7px 12px 7px 0;
      border-color: transparent #ffffff transparent transparent;
    }
    @media all and (max-width: 768px) {
      top: initial;
      left: 50%;
      bottom: calc(100% + 6px);
      transform: translate(-50%, 0%);
      &::before {
        top: initial;
        left: 50%;
        bottom: -12px;
        transform: translate(-50%, 0%);
        border-width: 12px 7px 0 7px;
        border-color: #ffffff transparent transparent transparent;
      }
    }
    .requirementsTitle {
      flex-shrink: 0;
      margin-bottom: 5px;
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #3D4040;
    }
    .requirement {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      color: #3D4040;
      &::before {
        content: '•';
        width: 16px;
        height: 19px;
        text-align: center;
        flex-shrink: 0;
      }
      &.checked {
        color: #77B4AA;
        &::before {
          content: '';
          background-image: url(/images/icons/requirement_checked.svg);
          background-position: center;
          background-size: 8px;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .passwordToggle {
    position: absolute;
    width: 38px;
    height: 38px;
    top: 8px;
    right: 0px;
    display: block;
    cursor: pointer;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 12px;
      background-color: $color2;
      mask: url(/images/icons/eye.svg) no-repeat center / contain;
    }
    &.active {
      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 1px;
        background-color: $color2;
        top: 75%;
        left: 50%;
        display: block;
        transform: rotate(45deg) translate(-75%, -52%);
      }
    }
  }
  .limitLabel {
    font-size: 14px;
    line-height: 16px;
    color: #666666;
  }
}
