@import '../../variables.scss';

.multiTextInput {
  padding: 8px 0px;
  input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
  }

  .reactSelect {
    .reactSelect__value-container {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow: auto;
      padding-left: 12px;
      padding-right: 12px;
      .reactSelect__multi-value {
        border-radius: 2px;
        flex-direction: row;
        padding: 5px 8px 5px 12px;
        margin-top: 2px;
        margin-bottom: 2px;
        margin-right: 6px;
        background: $outlineColor;
        min-width: auto;
        .reactSelect__multi-value__label {
          font-family: 'Montserrat';
          font-weight: 500;
          color: #3D4040;
          font-size: 12px;
          line-height: 15px;
          overflow: visible;
          padding: 0;
        }
      }
    }
    .reactSelect__indicators {
      display: none;
    }
    .reactSelect__menu {
      display: none;
    }
  }
}
