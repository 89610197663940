@import '../../_variables.scss';

.tabsView {
  position: relative;
  &.compact {
    .tabsContainer {
      .tabsList {
        .tabItem {
          font-size: 12px;
          padding: 10px 0px;
          text-transform: uppercase;
          .material-icons {
            height: 14px;
          }
        }
      }
    }  
  }
  .tabsContainer {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .tabsList {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .tabItem {
        font-size: 18px;
        font-weight: 700;
        color: #666666;
        cursor: pointer;
        padding: 15px 0px;
        border-bottom: 3px solid transparent;
        white-space: nowrap;
        z-index: 2;
        &+.tabItem {
          margin-left: 20px;
        }
        &.active {
          color: $primaryColor;
          border-bottom-color: $primaryColor;
        }
        .material-icons {
          color: inherit;
          height: 22px;
          padding: 0px 10px;
        }
      }
    }
  }
  .tabsBorder {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-bottom: solid 1px #dddddd;
  }
}
