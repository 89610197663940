@import '../../_variables.scss';

.switchInput {
  display: inline-block;
  height: 30px;
  width: 70px;
  cursor: pointer;
  user-select: none;
  background-color: #dddddd;
  pointer-events: auto;
  border-radius: 8px;
  .switcherIndicator {
    content: '';
    width: 35px;
    height: 28px;
    position: relative;
    top: 1px;
    left: 1px;
    border-radius: 8px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: all 0.15s ease 0s;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #999999;
      mask: url(/images/icons/uncheck.svg) no-repeat center;
      mask-size: 10px;
      transition: all 0.15s ease 0s;
    }
  }
  &.active {
    .switcherIndicator {
      left: 34px;
      background-color: $primaryColor;
      &::before {
        background-color: #ffffff;
        mask: url(/images/icons/check.svg) no-repeat center;
        mask-size: 14px;
        transition: all 0.15s ease 0s;
      }
    }
  }
}
