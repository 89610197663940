@import '../../_variables.scss';

.tagsSelect {
  margin-right: 11px;
  margin-left: 11px;
  .tags {
    display: flex;
    flex-direction: row;
    width: fit-content;
    background: $outlineColor;
    border-radius: 4px;
  }
  .tag {
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    padding: 6px 11px;
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s;
    flex-shrink: 0;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    color: #3D4040;
    &.selected {
      background: #4D8C86;
      color: #fff;
    }
    &:first-child {
      margin-left: 4px;
    }
    &:last-child {
      margin-right: 4px;
    }
  }
}

@media all and (max-width: 768px) {
  .tagsSelect {
    width: 100%;
    padding: 8px 0px;
  }
}
