@import '../../_variables.scss';

.labelsList {
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 3px;
  border: solid 1px #dddddd;
  padding: 4px 6px 0px 12px;
  .labelItem {
    margin-bottom: 4px;
    margin-right: 6px;
    border-radius: 2px;
    padding: 5px 10px;
    border-radius: 2px;
    background-color: $outlineColor;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3D4040;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
