.appointmentControlsView {
  position: absolute;
  left: 100px;
  bottom: 5px;
}

@media all and (max-width: 768px) {
  .appointmentControlsView {
  }
}
