@import '../../_variables.scss';

.bulkEditPopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  &.confirmationView {
    .overlay {
      .popupWindow {
        overflow: auto;
        .hint {
          width: 90%;
        }
        .popupControls {
          width: 90%;
        }
      }
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      overflow: auto;
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 70%;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px, rgba(0, 0, 0, 0.02) 0px 12px 16px;
      min-height: 70%;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      padding-left: 31px;
      padding-right: 31px;
      padding-bottom: 50px;
      padding-top: 42px;
      position: relative;
      .popupTitle {
        padding-right: 40px;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        color: #666566;
        width: 90%;
        margin-right: auto;
        padding-left: 11px;
      }
      .closeButton {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(/images/icons/close.svg) no-repeat center;
          mask-size: contain;
        }
      }
      .contentContainer {
        padding: 20px 0px 0px;
        .bulkField {
          display: flex;
          flex-direction: row;
          align-items: center;
          .bulkFieldContent {
            width: 85%;
            display: flex;
            flex-direction: row;
            .inputElem {
              flex: 1 0;
              width: 75%;
              margin-left: 14px;
              margin-right: 15px;
            }
            .action {
              width: 10%;
              display: flex;
              align-items: center;
              cursor: pointer;
              .cancel {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #0097D9;
              }
              span {
                display: flex;
                height: 100%;
                align-items: center;
                color: #757575;
              }
            }
          }
          label {
            width: 15%;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: right;
            color: #666666;
            text-transform: uppercase;
          }
          &.checkbox {
            .inputElem {
              max-width: 32px;
            }
          }
        }
      }
      .hint {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #999999;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        padding-left: 11px;
        &.confirmationHint {
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
      .popupControls {
        padding-top: 5px;
        display: flex;
        gap: 9px;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        padding-left: 11px;
        .popupControl {
          margin-top: 17px;
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $primaryColor;
          cursor: pointer;
          &.back {
            width: 50%;
            background-color: #ffffff;
            color: $primaryColor;
          }
          &.confirm {
            width: 50%;
            background-color: $primaryColor;
            color: #ffffff;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
          }
          &.uppercase {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  &.hidden {
    display: none;
  }
  &.confirmationView {
    .hint {
      width: 90%;
    }
    .popupControls {
      width: 90%;
    }
  }
}

@media all and (max-width: 768px) {
  .bulkEditPopup {
    .overlay {
      .popupWindow {
        max-width: 90%;
        .popupTitle {
          padding-right: 0;
          padding-left: 0;
          text-align: center;
        }
        .contentContainer {
          .bulkField {
            padding: 8px 0px;
            flex-direction: column;
            .bulkFieldContent {
              width: 100%;
              .inputElem {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
              }
              .action {
                margin-left: 14px;
              }
            }
            label {
              width: 100%;
              text-align: left;
              margin-bottom: 2px;
            }
          }
        }
        .hint {
          width: 100%;
          padding-left: 0;
        }
        .popupControls {
          flex-direction: column;
          width: 100%;
          padding-left: 0;
          .confirm, .back {
            width: 100%;
          }
          .popupControl {
            width: 100%;
            &.back {
              width: 100%;
            }
            &.confirm {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
