@import '../../_variables.scss';

.clientActivity {
  padding: 15px 0 25px;
  position: relative;
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 10px;
          height: 41px;
        }
      }
    }
    tbody {
      tr {
        td {
          white-space: normal;
          padding: 10px;
          overflow: hidden;
          border-top: 1px solid #dddddd;
          height: 41px;
          span {
            cursor: pointer;
            color: $primaryColor;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  button {
    &:disabled {
      border: 1px solid #D9D9D9;
      border-radius: 3px;
      color: #D9D9D9;
      background-color: #FFFFFF;
    }
  }
}

@media all and (max-width: 768px) {
  .bookingsInfo {
    table {
      overflow: hidden;
      margin-bottom: 20px;
      tbody {
        tr {
          td {
            &:first-child {
              color: #999999;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
