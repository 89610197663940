@import '../_variables.scss';

.loginView {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  text-align: center;
  background-color: $mainBackgroundColor;
  .loginForm {
    display: inline-block;
    width: 100%;
    max-width: 400px;
    padding: calc(40vh - 160px) 25px;
    text-align: left;
    .logo {
      width: 100%;
      height: 51px;
      background-image: url(/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 25px;
    }
    label {
      display: block;
      color: $color1;
      margin-bottom: 2px;
    }
    input {
      display: block;
      margin-bottom: 7px;
      width: 100%;
      border: none;
      padding: 7px 11px;
      outline: none;
      border: 1px solid #cccccc;
      border-radius: 4px;
      &::placeholder {
        color: #eeeeee;
      }
    }
    button, button:hover {
      width: 100%;
      background-color: $primaryColor;
      color: #ffffff;
      border: none;
      padding: 7px 21px;
      margin: 10px 0px;
      outline-color: $secondaryColor;
      text-transform: uppercase;
      border-radius: 4px;
    }
  }
}
