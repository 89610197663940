@import '../../_variables.scss';

.surveyQuestionsView {
  padding: 8px 0px;
  .itemsContainer {
    display: flex;
    flex-direction: column;
  }
  .questionItem {
    margin: 5px 0px 10px;
    position: relative;
    .upButton,
    .downButton,
    .removeButton,
    .addButton {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
      .material-icons {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .upButton {
      position: absolute;
      top: 10px;
      right: 85px;
    }
    .downButton {
      position: absolute;
      top: 10px;
      right: 50px;
    }
    .removeButton {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .questionIndex {
      margin-bottom: 4px;
    }
    .questionLabel {
      margin: 5px 0px 0px;
      font-size: 12px;
      font-weight: 700;
      color: #666666;
      text-transform: uppercase;
    }
    .checkboxInput {
      padding: 8px 0px;
    }
    .optionsContainer {
      display: flex;
      flex-direction: column;
      .optionItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .textInput {
          width: 100%;
        }
        .removeButton {
          position: initial;
          margin-right: 8px;
        }
      }
    }
  }
}
