@import '../../_variables.scss';

.notificationPopup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1300;
  transition: right 0.25s ease 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  &:not(.show) {
    right: -100%;
  }
  .notificationContainer {
    position: relative;
    background-color: $highlightColor;
    border: 1px solid $primaryColor;
    width: 300px;
    display: inline-flex;
    flex-direction: column;
    padding: 15px 15px 20px;
    border-radius: 10px;
    border-radius: 4px;
		box-shadow:
			0px 1px 3px 0px rgba(0,0,0,0.2),
			0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 2px 1px -1px rgba(0,0,0,0.12);
    .notificationTitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: $primaryColor;
      margin-bottom: 10px;
      padding-right: 40px;
    }
    .notificationText {
      font-size: 14px;
      line-height: 18px;
      color: #333333;
    }
    .notificationClose {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background-color: #666666;
      mask: url(/images/icons/close.svg) no-repeat center;
      mask-size: 14px;
    }
  }
}

@media all and (min-width: 768px) {
  .snackbar {
    .snackbarContainer {
      width: 480px;
    }
  }
}
