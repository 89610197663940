.sessionSettingsInput {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .infoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      width: 275px;
      font-size: 14px;
      color: #666666;
      font-weight: 500;
      text-align: center;
      &+span {
        margin-left: 20px;
      }
    }
  }
  .settingRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    .settingItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      +.settingItem {
        margin-left: 20px;
      }
      .textInput {
        margin-left: 10px;
        width: 120px;
      }
      span {
        width: 65px;
        margin-right: 10px;
        font-size: 14px;
        color: #666666;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
}
