@import '../../variables.scss';

.photosInput {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
  .photoItem {
    width: 108px;
    height: 108px;
    padding: 4px;
    .photoContent {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        .removeButton {
          opacity: 1;
        }
      }
      .removeButton {
        width: 100%;
        padding: 6px;
        margin-top: auto;
        background-color: rgba(255,255,255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.3;
      }
      &.add {
        background-color: #dddddd;
        cursor: pointer;
        span {
          font-size: 42px;
          color: $primaryColor;
        }
        input {
          display: none;
        }
      }
    }
  }
}