@import '../_variables.scss';

.objectListView {
  .bulkSelect {
    display: flex;
    .selectItems {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 32px;
    }
    .selectEdit {
      &:disabled {
        background: #4D8C86;
        opacity: 0.3;
        color: #FFFFFF;
      }
    }
  }
  .objectsListTable {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    .card {
    }
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 10px;
          height: 41px;
          &.sortable {
						cursor: pointer;
						&:hover {
							background-color: rgba(0,0,0,0.08);
						}
					}
          .sortIcon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            margin-left: 12px;
            height: 16px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
          overflow: hidden;
          white-space: nowrap;
          border-top: 1px solid #dddddd;
          height: 41px;
          &.control {
            padding: 0px 5px;
            overflow: visible;
          }
        }
        &:hover {
          background-color: $secondaryBackgroundColor;
        }
      }
    }
  }
  .importButton {
    position: fixed;
    z-index: 5;
    right: 115px;
    bottom: 42px;
  }
  .bulk {
    position: fixed;
    z-index: 5;
    right: 115px;
    bottom: 115px;
  }
  .createButton {
    position: fixed;
    right: 35px;
    bottom: 40px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #ffffff;
    background-color: $primaryColor;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu {
    position: fixed;
    bottom: 10px;
    right: 100px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: left;
    z-index: 950;
    transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: translate(0%, 0%) scale(1.0, 1.0);
    width: fit-content;
    &.hidden {
      transform: translate(calc(10px - 50%), calc(10px - 50%)) scale(0.0, 0.0);
    }
    .menuItem {
      position: relative;
      width: fit-content;
      padding: 10px;
      margin: 5px 0px;
      cursor: pointer;
      white-space: nowrap;
      &.danger {
        .menuItemTitle {
          color: $errorColor;
        }
      }
      &.disabled {
        cursor: not-allowed;
        .menuItemTitle {
          color: #cccccc;
        }
      }
      .menuItemTitle {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      .menuItemDescription {
        margin-top: 4px;
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .objectListView {
    .bulkSelect {
      display: flex;
      flex-direction: row;
      flex-direction: column;
      .selectAllMobile {
        display: flex;
        flex-direction: row;
        padding-bottom: 15px;
        .checkboxInput {
          display: flex;
        }
        span {
          padding-left: 5px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #999999;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .selectItems {
        text-align: left;
        justify-content: flex-start;
        padding-bottom: 15px;
      }
    }
    .card {
      margin-bottom: 30px;
      table {
        margin-top: -10px;
        margin-bottom: 0px;
        tbody {
          tr {
            &:first-child {
              td {
                border-top: none;
              }
            }
            td {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
